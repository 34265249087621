/**
 * Load more posts
 *
 * Source: https://www.daddydesign.com/wordpress/how-to-load-more-posts-in-wordpress-with-jquery/
 */
jQuery(function($){
	$('.load_more a').on('click', function(e){
		e.preventDefault();
		console.log('klik');
		var link = $(this).attr('href');
		$('.load_more').html('<span class="loader">Meer foto\'s ophalen...</span>');
		$.get(link, function(data) {
			var post = $(".card-columns .card ", data);
			$('.card-columns').append(post);
		});
		$('.load_more').load(link+' .load_more a');
	});
});
